@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Inter, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Set the font weight to bold (originally bolder) for strong tags that we cannot style directly e.g. render from markdown */
strong {
  font-weight: bold;
}

.grecaptcha-badge {
  bottom: 20px !important;
}

.fp-text-input {
  @apply border-2 rounded-lg;
  @apply focus:border-primary;
  @apply w-full relative justify-center;
  @apply bg-white text-black text-sm;
  @apply py-3 px-4;
  @apply focus:outline-none;
}

.fp-btn {
  @apply w-full relative justify-center;
  @apply py-3 px-4 cursor-pointer;
  @apply bg-primary text-white text-sm font-bold tracking-wider;
  @apply border border-transparent rounded-md;
  @apply focus:outline-none focus:border-white;
  @apply hover:bg-primary-300;
}

.fp-btn.loading {
  @apply bg-[#85bab8] hover:bg-[#85bab8] cursor-default;
}
